import React, {useEffect, useState} from 'react'
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {ReactComponent as DisconnectedSvg} from "../../assets/disconnected.svg";

function DisconnectedModal() {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        let getDemoParam = (window.location.search).replace('?demo=', '');

        if (getDemoParam === 'disconnected') {
            setModal(true);
        }
    }, []);

    return (
        <Modal isOpen={modal} toggle={toggle} centered backdrop="static"
               className="modal-dialog-zoom">
            <ModalBody>
                <div className="connection-error">
                    <h4 className="text-center">Application disconnected...</h4>
                    <DisconnectedSvg/>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <Button color="primary" size="lg" onClick={toggle}>Reconnect</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DisconnectedModal
