import React, {useEffect} from 'react'
import SidebarIndex from "./Sidebars/index"
import Navigation from "./Navigation"
import Profile from "./Sidebars/Profile"
import Chat from "./Partials/Chat"
import DisconnectedModal from "./Modals/DisconnectedModal";
import {useDispatch} from "react-redux";
import {profileAction} from "../Store/Actions/profileAction";
import {sidebarAction} from "../Store/Actions/sidebarAction";

function Layout() {

    let dispatch = useDispatch();

    useEffect(() => {
        document.querySelector('*').addEventListener('click', (e) => {
            if (document.body.classList.contains('navigation-open') && e.target.nodeName === 'BODY') {
                document.body.classList.remove('navigation-open')
            }
        });

        let getDemoParam = (window.location.search).replace('?demo=', '');

        if (getDemoParam === 'dark') {
            document.body.classList.add('dark');
        } else if (getDemoParam === 'rtl') {
            document.body.classList.add('rtl');
        } else if (getDemoParam === 'user-profile') {
            dispatch(profileAction(true));
        } else if (getDemoParam === 'dark-rtl') {
            document.body.classList.add('dark');
            document.body.classList.add('rtl');
        } else if (getDemoParam === 'add-friend') {
            dispatch(sidebarAction("Friends"));
        }
    }, []);

    return (
        <div className="layout">
            <Navigation/>
            <div className="content">
                <SidebarIndex/>
                <Chat/>
                <Profile/>
                <DisconnectedModal/>
            </div>
        </div>
    )
}

export default Layout
